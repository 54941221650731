<template>
    <div class="pp-schedule-day">
        <div class="pp-schedule-day-name">{{ day }}</div>
        <div v-for="dash in dashes"
         :key="dash"
         class="schedule-divider" />
        <p class="pp-schedule-time">{{ time }}</p>
        <p class="pp-schedule-title">{{ title }}</p>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: 'PP Flex'
        },
        day: {
            type: String,
            required: true,
            default: 'E'
        },
        time: {
            type: String,
            required: true,
            default: '18:00'
        },
        dashes: {
            type: Number,
            required: true,
            default: 2
        }

    }
}
</script>
<style>
.pp-schedule-day {
    width: 100%;
    padding-right: 5px;
    border-right: 1px solid var(--divider);
    display: flex;
    flex-direction: column;
}

.pp-schedule-day:last-of-type {
    padding: 0;
}

.pp-schedule-day:last-child {
    border-right: none;
}

.pp-schedule-time {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 600;
    color: var(--red);
    text-align: center;
}

.pp-schedule-title {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    /* white-space: nowrap; */
}

.schedule-divider {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: var(--divider);
    margin-top: 20px;
}


.pp-schedule-day-name {
    background-color: var(--red);
    color: white;
    font-size: 24px;
    font-weight: 600;
    padding: 2px;
    text-align: center;
}

@media screen and (width < 500px) {
    .pp-schedule-day {
        max-width: 100px;
    }

    .pp-schedule-day-name {
        font-size: 14px;
    }

    .pp-schedule-time {
        font-size: 14px;
    }

    .pp-schedule-title {
        font-size: 14px;
    }
}
</style>
