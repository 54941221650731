<template>
    <div class="menu-wrapper">
        <ul class="menu">
            <li @click="emitEvent('profile')"
             id="profile">
                <div class="icon-wrapper">
                    <img class="icon"
                     :src="user"
                     alt="user">
                </div>
                {{ $t('dashboard.profile') }}
            </li>
            <li @click="emitEvent('classes')"
             id="classes">
                <div class="icon-wrapper">
                    <img class="icon"
                     :src="calendar"
                     alt="calendar">
                </div>
                {{ $t('dashboard.bookings') }}
            </li>
            <li v-if="false"
             @click="emitEvent('tests')"
             id="tests">
                <div class="icon-wrapper">
                    <img class="icon"
                     :src="test"
                     alt="tests">
                </div>
                Tests
            </li>
            <li @click="emitEvent('contract')"
             id="contract">
                <div class="icon-wrapper">
                    <img class="icon"
                     :src="doc"
                     alt="contract" />
                </div>
                {{ $t('dashboard.contract') }}
            </li>
            <li @click="emitEvent('invoices')"
             id="invoices">
                <div class="icon-wrapper">
                    <img class="icon"
                     :src="invoices"
                     alt="invoices" />
                </div>
                {{ $t('dashboard.invoices') }}
            </li>
            <li @click="emitEvent('gifts')"
             id="gifts"
             class="space-between">
                <div class="left-sided">
                    <div class="icon-wrapper">
                        <img class="icon"
                         :src="gift"
                         alt="gifts" />
                    </div>
                    {{ $t('dashboard.gifts') }}
                </div>


            </li>
            <li @click="logout"
             class="logout">
                {{ $t('dashboard.logout') }}
            </li>
        </ul>

    </div>
</template>

<script>
import user from '@/assets/svg/user.svg'
import calendar from '@/assets/svg/calendar-dash.svg'
import doc from '@/assets/svg/doc.svg'
import test from '@/assets/svg/test.svg'
import invoices from '@/assets/svg/eurosign.svg'
import ios from '@/assets/svg/ios.svg'
import android from '@/assets/img/android.png'
import gift from '@/assets/svg/gift.svg'

export default {
    props: {
        selected: {
            type: String,
            required: true
        },
        hasDebt: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            user,
            calendar,
            gift,
            doc,
            test,
            invoices,
            ios,
            android,
            showHidden: false
        }
    },
    mounted() {
        // set the selected menu item to active
        document.getElementById(this.selected).classList.add("active")
    },
    methods: {
        showInTestMode() {

            if (localStorage.getItem('testmode') == 'true') {
                this.showHidden = true
                return
            }
            this.showHidden = false
        },
        emitEvent(name) {
            document.getElementsByClassName("active")[0]
                .classList.remove("active")
            document.getElementById(name).classList.add("active")
            this.$emit('itemClicked', name)
        },
        logout() {
            // fetch request to logout
            fetch('https://app.35fit.com/api/users/logout', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' +
                        localStorage.getItem('token')
                },
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        localStorage.removeItem('token')
                        this.$router.push('/')
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.message == 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }

                })

        }

    },
    created() {
        // run test mode
        this.showInTestMode()
    }


}
</script>

<style scoped>
.menu-wrapper {
    width: 100%;
    height: 100%;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.warn {
    background-color: var(--red);
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.left-sided {
    display: flex;
    align-items: center;
    gap: 10px;
}

.menu {
    width: 100%;
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    list-style: none;
    margin-bottom: 35px;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
}

.logout {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    align-items: center;
    background-color: var(--gray);
    color: red;
}

.logout:hover {
    background-color: var(--dark-gray) !important;
}


.icon-wrapper {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    height: 15px;
}

.menu li {
    position: relative;
    width: 100%;
    padding: 10px 20px;
    border-radius: var(--card-br);
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.menu li:hover {
    background-color: var(--gray);
}

.active {
    background-color: var(--dark-gray);
}

.active::after {
    content: '';
    position: absolute;
    top: 6px;
    left: -10px;
    width: 5px;
    height: 70%;
    background-color: var(--light-green);
    border-radius: 10px;
}
</style>
