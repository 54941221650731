<template>
    <main class="register-user">
        <div class="login-wrapper"
         :style="{
            background: 'url(' + loginPic + ')'
        }">

            <transition name="bounce"
             mode="out-in">

                <div v-if="stage == 0"
                 class="login-left">
                    <transition name="bounce"
                     mode="out-in">
                        <h3 :key="introText"
                         class="register-intro-text">{{ introText }}</h3>
                    </transition>
                </div>
                <div v-else-if="stage > 0"
                 class="login-left">
                    <div class="register-logo">
                        <img v-if="stage < 5"
                         :src="logo"
                         alt="logo">
                    </div>
                    <transition name="bounce"
                     mode="out-in">
                        <h2 v-if="stage < 6"
                         :key="changableText">{{ changableText }}</h2>
                    </transition>
                    <NotifyWarning v-if="inputNotify.show"
                     :title="inputNotify.title"
                     :icon="inputNotify.icon"
                     :text="inputNotify.text"
                     :color="inputNotify.color"
                     @close="inputNotify.show = false" />

                    <transition name="bounce"
                     mode="out-in">
                        <div v-if="stage == 1"
                         class="login-text-group">
                            <h4 class="register-headline">{{ $t('registration.prefLang') }}</h4>
                            <MultipleSelect :options="languages"
                             :initSelected="newUser.languages"
                             :footnote="$t('registration.footnote')"
                             :label="$t('registration.selectLang')"
                             @select="(value) => { this.newUser.languages = value; formDateOptions(); }" />

                            <div v-if="newUser.languages.length > 0"
                             class="login-text-group">



                                <InputNotify
                                 v-if="this.trialNotRequired === false && (this.newUser.trial_booking.time == $t('dashboard.pages.booking.modal.selectTime') || this.newUser.trial_booking.date == $t('dashboard.pages.booking.modal.selectDate'))"
                                 :text="warningText"
                                 style="max-width: 100%;" />

                                <label class="checkbox-container">
                                    <input type="checkbox"
                                           v-model="trialNotRequired">
                                    <span class="checkmark"></span>
                                    <p v-html="$t('registration.trialNotRequired')"></p>
                                </label>

                                <div v-if="this.trialNotRequired === false">
                                    <h4 class="register-headline">{{ $t('dashboard.pages.booking.modal.dateTime') }}</h4>

                                    <SelectTag :label="this.newUser.trial_booking.date"
                                     :key="updDate"
                                     :options="dateOptions"
                                     @select="(selected) => formTimeOptions(selected)" />

                                    <SelectTag v-if="timeOptions.length > 0"
                                     :options="timeOptions"
                                     :label="this.newUser.trial_booking.time"
                                     :key="updTime"
                                     @select="(time) => this.newUser.trial_booking.time = time" />
                                </div>
                            </div>
                        </div>
                        <div v-else-if="stage == 2"
                         class="login-text-group">
                            <h4 class="register-headline">{{ $t('registration.name') }}</h4>
                            <input v-model="newUser.name"
                             type="text"
                             autocomplete="given-name"
                             placeholder="Taanel">
                            <h4 class="register-headline">{{ $t('registration.surname') }}</h4>
                            <input v-model="newUser.surname"
                             type="text"
                             autocomplete="family-name"
                             placeholder="Saar">
                            <h4 class="register-headline">{{ $t('registration.idcode') }}</h4>
                            <label class="checkbox-container">
                                <input type="checkbox"
                                       v-model="iHaveNoIdCode">
                                <span class="checkmark"></span>
                                <p v-html="$t('registration.iHaveNoIdCode')"></p>
                            </label>
                            <input v-if="iHaveNoIdCode === false" type="text"
                             pattern="\d*"
                             placeholder="39103120035"
                             maxlength="11"
                             v-model="newUser.idcode">
                        </div>
                        <div v-else-if="stage == 3"
                         class="login-text-group">
                            <h4 class="register-headline">{{ $t('registration.gender') }}</h4>
                            <SelectTag :options="genderOptions"
                             :label="$t('registration.male')"
                             mode="json"
                             @select="(value) => this.newUser.gender =
            genderOptions.find((option) => option.id == value).id" />
                            <h4 class="register-headline">{{ $t('registration.dob') }}</h4>
                            <input type="date"
                             :title="$t('registration.dob')"
                             default="1990-01-01"
                             :placeholder="$t('registration.dob')"
                             :max="maxDate"
                             v-model="newUser.birthdate">
                        </div>
                        <div v-else-if="stage == 4"
                         class="login-text-group">
                            <h4 class="register-headline">{{ $t('registration.address') }}</h4>
                            <SelectTag :options="cities"
                             mode="json"
                             label="Tallinn"
                             @select="(value) => this.newUser.city_id =
            cities.find((option) => option.id == value).id" />
                            <input type="text"
                             placeholder="Gonsiori 33"
                             v-model="newUser.address">
                            <input type="number"
                             placeholder="11312"
                             pattern="\d*"
                             v-model="newUser.zip">
                            <h4 class="register-headline">{{ $t('registration.contacts') }}</h4>
                            <input type="tel"
                             placeholder="+372 5333 5333"
                             v-model="newUser.phone">
                        </div>
                        <div v-else-if="stage == 5"
                         class="login-text-group">
                            <h4 class="register-headline">{{ $t('registration.email') }}</h4>
                            <input type="email"
                             id="email"
                             placeholder="example@35fit.com"
                             v-model="newUser.email">
                            <h4 class="register-headline">{{ $t('registration.password') }}</h4>
                            <input type="password"
                             id="password"
                             :placeholder="$t('login.passwordPlaceholder')"
                             v-model="newUser.password">
                            <input type="password"
                             id="password"
                             :placeholder="$t('registration.confirmPass')"
                             v-model="checkPassword">
                            <label class="checkbox-container">
                                <input type="checkbox"
                                 v-model="confirm.data">
                                <span class="checkmark"></span>
                                <p v-html="$t('registration.dataAgree')"></p>
                            </label>
                            <label class="checkbox-container">
                                <input type="checkbox"
                                 v-model="confirm.rules">
                                <span class="checkmark"></span>
                                <p v-html="$t('registration.rulesAgree')"></p>
                            </label>
                        </div>
                        <div v-else
                         class="login-text-group">
                            <img :src="registered"
                             alt="success">
                            <div class="registered-success">
                                <h2>{{ $t('registration.success') }}!</h2>
                                <p>{{ $t('registration.emailSent') }}</p>
                            </div>
                        </div>
                    </transition>
                    <div class="login-buttons">
                        <LoaderView v-if="loading"
                         style="height: unset;" />
                        <MainButton v-else
                         @click="nextButton"
                         :text="$t('registration.continue')" />
                    </div>

                    <button v-if="stage != 6"
                     @click="backAction"
                     class="login-back">
                        <img :src="back"
                         class="login-back-arrow"
                         alt="back button">
                    </button>
                    <transition name="bounce">
                        <div class="reg-lang-open"
                         @mouseover="langMenuOpen = true"
                         @mouseleave="langMenuOpen = false">
                            <span>{{ this.$i18n.locale }}</span>

                            <transition name="bounce">
                                <div class="reg-lang-wrap"
                                 v-if="langMenuOpen">

                                    <LanguageSelect />
                                </div>
                            </transition>
                        </div>
                    </transition>
                    <div class="stage-indicator-wrapper">
                        <div :style="{ background: circleStyle }"
                         class="stage-indicator"></div>
                    </div>
                </div>
            </transition>
            <div class="login-right"></div>
        </div>
    </main>
</template>

<script>
import back from '@/assets/svg/arrow-black.svg';
import logo from '@/assets/svg/logo.svg'
import MainButton from '@/components/buttons/MainButton.vue';
import SelectTag from '@/components/selectors/SelectTag.vue';
import registered from '@/assets/svg/registered.svg';
import router from '@/router';
import NotifyWarning from '@/components/warnings/NotifyWarning.vue';
import info from '@/assets/svg/info.svg';
import warning from '@/assets/svg/warning.svg';
import LoaderView from '@/components/buttons/LoaderView.vue';
import loginPic from '@/assets/img/login.webp';
import { useMeta } from 'vue-meta'
import MultipleSelect from '@/components/selectors/MultipleSelect.vue';
import InputNotify from '@/components/InputNotify.vue';
import LanguageSelect from '@/components/selectors/LanguageSelect.vue';

export default {
    name: "RegisterView",
    components: {
        MainButton,
        SelectTag,
        NotifyWarning,
        LoaderView,
        MultipleSelect,
        InputNotify,
        LanguageSelect
    },
    data() {
        return {
            maxDate: new Date().toISOString().split("T")[0],
            vh: window.visualViewport.height + 'px',
            loading: false,
            langMenuOpen: false,
            loginPic,
            logo,
            info,
            warning,
            registered,
            back,
            stage: 0,
            warningText: this.$t('dashboard.pages.booking.modal.firstTrialText'),
            inputNotify: {
                show: false,
                title: this.$t('registration.error'),
                text: '',
                icon: '',
                color: 'info',
            },
            logoPosition: "",
            genderOptions: [
                { id: 0, name: this.$t('registration.female') },
                { id: 1, name: this.$t('registration.male') }
            ],
            languages: ["Eesti", "English", "Русский"],
            cities: [
                { id: 4370, name: "Tallinn" },
                { id: 4683, name: "Tartu" },
                { id: 4684, name: "Narva" },
                { id: 4685, name: "Pärnu" },
                { id: 4690, name: "Rae" },
                { id: 4691, name: "Jõelähtme" },
                { id: 4692, name: "Saku" },
                { id: 4698, name: "Paide" },
                { id: 4713, name: "Järveküla" },
                { id: 4779, name: "Viimsi" },
                { id: 4843, name: "Laiaküla" },
                { id: 4844, name: "Maardu" },
                { id: 4845, name: "Püha küla" },
                { id: 4847, name: "Loo" },
                { id: 4924, name: "Peetri" },
                { id: 5065, name: "Tabasalu" },
                { id: 5097, name: "Rapla" },
                { id: 5110, name: "Kaberla" },
                { id: 5128, name: "Laagri" },
                { id: 5172, name: "Jüri" },
                { id: 5365, name: "Tartu" },
                { id: 5366, name: "Raesiku vald" },
                { id: 5776, name: "Kiisa" },
                { id: 6339, name: "Paldiski" },
            ],
            introText: this.$t('registration.introText'),
            changableText: this.$t('registration.changableText'),
            newUser: {
                name: "",
                surname: "",
                gender: 1,
                birthdate: "1990-01-01",
                city_id: 4370,
                address: "",
                zip: "",
                email: "",
                phone: "",
                idcode: "",
                password: "",
                languages: [],
                trial_booking: {
                    date: this.$t('dashboard.pages.booking.modal.selectDate'),
                    time: this.$t('dashboard.pages.booking.modal.selectTime')
                }
            },
            dateOptions: [],
            timeOptions: [],
            activeTrials: null,
            schedule: [],
            updTime: 0,
            updDate: 100,
            checkPassword: "",
            confirm: {
                rules: false,
                data: false
            },
            trialNotRequired: false,
            iHaveNoIdCode: false
        }
    },
    watch: {
        newUser: {
            handler: function (val) {
                if (val.name != "") {
                    val.name = val.name.replace(/[а-яА-ЯЁё]/, '');
                }
                if (val.surname != "") {
                    val.surname = val.surname.replace(/[а-яА-ЯЁё]/, '');
                }
                if (val.address != "") {
                    // replace all cyrillic letters with empty string
                    val.address = val.address.replace(/[а-яА-ЯЁё]/, '');
                }
                if (val.zip != "") {
                    val.zip = val.zip.replace(/[а-яА-ЯЁё]/, '');
                }
                if (val.idcode != "") {

                    val.idcode = val.idcode.replace(/[а-яА-ЯЁё]/, '');
                }
            },
            deep: true
        },
    },
    computed: {
        circleStyle() {
            return "conic-gradient(#59b894 0% " + (100 / 6 * this.stage) + "%, #fff 19% 81%)"
        }

    },
    methods: {
        formDateOptions() {
            // NOTE: trial training is available only for 7 days from 17.04
            let date
            // if (this.isTrial) {
            //     date = new Date('2023-04-16');
            // } else {
            // }
            date = new Date();

            let today = `${(date.getDate() < 10 ? '0' : '') + (date.getDate())}.${((date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1))}.${date.getFullYear()}`;
            let options = [];
            for (let i = 0; i < 7; i++) {
                let day = (date.getDate() < 10 ? '0' : '') + (date.getDate()); // add 0 to the day if it's less than 10
                let month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) // add 0 to the month if it's less than 10
                let year = date.getFullYear();
                let addDate = `${day}.${month}.${year}`

                if (addDate != today) {
                    options.push(addDate);
                }

                date.setDate(date.getDate() + 1);
            }
            this.newUser.trial_booking.date = this.$t('dashboard.pages.booking.modal.selectDate');
            this.newUser.trial_booking.time = this.$t('dashboard.pages.booking.modal.selectTime');
            this.timeOptions = [];
            this.updDate++;
            this.dateOptions = options;
        },
        inputNotifyMessage() {
            this.inputNotify = {
                show: true,
                title: "Invalid input",
                text: "You cannot use cyrillic letters in this field",
                icon: warning,
                color: 'red'
            }

            setTimeout(() => {
                this.inputNotify.show = false;
            }, 5000);
        },
        register() {
            this.loading = true;
            const sendData = this.newUser;
            if (this.trialNotRequired === true) {
                sendData.trial_booking.date = "";
                sendData.trial_booking.time = "";
            }
            fetch('https://app.35fit.com/api/users/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sendData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        this.stage = 6;
                        this.loading = false;
                        return
                    }
                    this.inputNotify = {
                        show: true,
                        title: this.$t('registration.errorCreating'),
                        text: data.error,
                        icon: this.warning,
                        color: 'red'
                    }
                    this.loading = false;

                })
                .catch((error) => {
                    this.inputNotify = {
                        show: true,
                        title: this.$t('registration.errorCreating'),
                        text: error.message,
                        icon: this.warning,
                        color: 'red'
                    }
                    this.loading = false;

                });
        },
        backAction() {
            if (this.stage > 1) {
                this.stage -= 1
                return
            }

            this.$router.go(-1) || this.$router.push('/')
        },
        redBorders(selector, mode) {
            const inputs = document.querySelectorAll(selector);
            if (mode) {
                inputs.forEach(input => {
                    input.classList.add('red-border')
                })
            } else {
                inputs.forEach(input => {
                    input.classList.remove('red-border')
                })
            }
        },
        validateEmail(email) {
            // validate email input
            const re = /\S+@\S+\.\S+/;
            if (re.test(email) || email === '') {
                this.redBorders('#email', false);

                return true;
            }

            this.redBorders('#email', true);

            return false;
        },
        runIntro() {
            setTimeout(() => {
                this.introText = this.$t('registration.introText2')
                setTimeout(() => {
                    this.stage += 1
                }, 1500);
                this.changableText = "Connected to Results"
            }, 1200);

        },
        fillFields() {
            this.inputNotify = {
                show: true,
                title: this.$t('registration.emptyTitle'),
                text: this.$t('registration.emptyText'),
                icon: info,
                color: 'info'
            }

        },
        sendMessageToiOSApp() {
            // post message to iOS app
            window.postMessage("Registration complete", "*");
        },
        nextButton() {
            this.sendMessageToiOSApp()
            if (this.stage < 6) {
                switch (this.stage) {
                    case 1:

                        if (this.newUser.languages.length == 0) {
                            this.fillFields()
                            return
                        }

                        if (this.trialNotRequired === false && (this.newUser.trial_booking.date == this.$t('dashboard.pages.booking.modal.selectDate') ||
                            this.newUser.trial_booking.time == this.$t('dashboard.pages.booking.modal.selectTime'))) {
                            this.inputNotify = {
                                show: true,
                                title: this.$t('registration.passErrors.selectTime'),
                                text: this.$t('registration.passErrors.selectTimeText'),
                                icon: info,
                                color: 'info'
                            }

                            return
                        }

                        this.changableText = this.$t('registration.changableText');
                        this.stage += 1;

                        break;
                    case 2:
                        if (this.newUser.name == "" || this.newUser.surname == ""
                            || (this.newUser.idcode == "" && this.iHaveNoIdCode === false)) {
                            this.redBorders('input', true)
                            this.fillFields()
                            return
                        }
                        this.redBorders('.red-border', false)
                        this.stage += 1
                        this.changableText = this.$t('registration.stageText.first') + this.newUser.name + "!"
                        break;
                    case 3:
                        if (this.newUser.birthdate == "") {
                            this.fillFields()
                            return
                        }
                        this.stage += 1
                        this.changableText = this.$t('registration.stageText.second')
                        break;
                    case 4:
                        if (this.newUser.address == "" ||
                            this.newUser.zip == "" ||
                            this.newUser.phone == "") {
                            this.redBorders('input', true)
                            this.fillFields()
                            return
                        }
                        this.redBorders('.red-border', false)
                        this.stage += 1
                        this.changableText = this.$t('registration.stageText.third')
                        break;
                    case 5:
                        this.redBorders('.red-border', false)
                        if (this.newUser.email == "" ||
                            this.newUser.password == "" ||
                            this.checkPassword == "") {
                            this.redBorders('input', true)
                            this.fillFields()
                            return
                        }

                        if (this.newUser.password != this.checkPassword) {
                            this.inputNotify = {
                                show: true,
                                title: this.$t('registration.passErrors.doNotMatch'),
                                text: this.$t('registration.passErrors.doNotMatchText'),
                                icon: info,
                                color: 'info'
                            }

                            this.redBorders('#password', true)
                            return
                        }

                        if (this.newUser.password.length <= 7) {
                            this.inputNotify = {
                                show: true,
                                title: this.$t('registration.passErrors.tooShort'),
                                text: this.$t('registration.passErrors.tooShortText'),
                                icon: info
                            }


                            this.redBorders('#password', true)
                            return
                        }

                        if (this.confirm.data == false || this.confirm.rules == false) {
                            this.inputNotify = {
                                show: true,
                                title: this.$t('registration.passErrors.legalConsent'),
                                text: this.$t('registration.passErrors.legalConsentText'),
                                icon: info,
                                color: 'info'
                            }

                            return
                        }

                        if (!this.validateEmail(this.newUser.email)) {
                            this.inputNotify = {
                                show: true,
                                title: this.$t('registration.passErrors.invalidMail'),
                                text: this.$t('registration.passErrors.invalidMailText'),
                                icon: warning,
                                color: 'red'
                            }

                            return
                        }
                        this.register()
                        this.changableText = this.$t('registration.stageText.fourth')
                        break;
                }

            } else {
                this.sendMessageToiOSApp()
                router.push("/login")
            }

        },
        async getTrials() {
            fetch('https://app.35fit.com/api/users/reserves/upcoming/trials', {
                method: 'GET',
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    this.activeTrials = data;
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getSchedule() {
            fetch("https://app.35fit.com/api/users/reserves/upcoming/schedule", {
                method: "GET"
            })
                .then(res => {
                    if (200 !== res.status)
                        throw new Error(res.status);
                    return res.json()
                })
                .then(data => {
                    this.schedule = data;
                })
                .catch(err => {
                    console.log(err)
                })
        },
        formLangTime(date) {
            var options = [];
            this.warningText = this.$t('dashboard.pages.booking.modal.firstTrialText')
            // find all trials for this day
            let thisDayTrials = this.activeTrials.filter(trial => {
                return Date.parse(trial.start.split('T')[0]) == Date.parse(date.split('.').reverse().join('-'))
            })
            let languages = {
                23996: ["Русский", "Eesti"],
                25332: ["Русский", "English", "Eesti"]
            }
            function r (e, t) {
                let n = e.filter(e => t.indexOf(e) >= 0);
                return n.length > 0
            }

            let day = date.split('.').reverse().join('-');
            let timeForUser = [];
            let userLanguages = this.newUser.languages;

            this.schedule.forEach(function(e) {
                let t = e.start.split("T");
                let n = e.end.split("T");
                t[0] === day && languages[e.id] && r(languages[e.id], userLanguages) && timeForUser.push({
                    start: t[1].split(":")[0],
                    end: n[1].split(":")[0]
                })}
            );

            timeForUser.sort((e,t) => e.start - t.start);

            timeForUser.forEach(function(e) {
                for (let i = e.start; i < e.end; i++) {
                    let isAvailable = true;
                    thisDayTrials.forEach(trial => {
                        let trialTime = trial.start.split("T")[1].split(":")[0];
                        if (trialTime == i) {
                            isAvailable = false;
                        }
                    });
                    isAvailable && -1 === options.indexOf(`${i}:00`) && options.push(`${i}:00`)
                }
            });

            this.timeOptions = options;

            if (this.timeOptions.length == 0) {
                this.warningText = this.$t('dashboard.pages.booking.modal.notime');
            }
        },
        formTimeOptions(date) {
            this.newUser.trial_booking.date = date;
            this.newUser.trial_booking.time = this.$t('dashboard.pages.booking.modal.selectTime');
            this.updTime += 1;
            this.formLangTime(date);
        },

    },
    beforeMount() {
        useMeta({
            title: this.$t('pagenames.register'),
            link: [
                { rel: 'canonical', href: 'https://35fit.com/register' },
            ],
            description: "Treeningute alustamiseks on vaja vaid lühikest registreerimist.",
        })
    },
    mounted() {
        // add event listener when stage changes
        this.runIntro();
        this.formDateOptions();
        this.getTrials();
        this.getSchedule();
        // max date for birthdate
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        const maxDateFormatted = maxDate.toISOString().split('T')[0];
        this.maxDate = maxDateFormatted
    },


}
</script>

<style>
.reg-lang-open {
    position: absolute;
    top: 20px;
    left: 70px;
}

.reg-lang-open span {
    width: 38px;
    height: 38px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: default;
    user-select: none;
    text-transform: uppercase;
}

.reg-lang-wrap {
    position: absolute;
    left: -100%;
    z-index: 100;
    padding-top: 10px;
}


.checkbox-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    position: relative;
    padding-left: 35px;
    height: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container p {
    font-size: 12px;
    line-height: 15px;
}

.checkbox-container input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 25px;
    width: 25px;
    border-radius: 7px;
    background-color: var(--dark-gray);
    transition: all 0.2s ease-in-out;
}

.checkbox-container:hover input~.checkmark {
    background-color: var(--gray);
}

.checkbox-container input:checked~.checkmark {
    background-color: var(--dark-blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.registered-success {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.register-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.113);
    width: 100%;
    height: 100vh;
    height: 100svh;
    padding: 0 10px;
    min-height: 650px;
}

@media screen and (max-width: 840px) {
    .register-user {
        padding: 0;
    }
}

.register-headline {
    padding-left: 10px;
}

.stage-indicator-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-intro-text {
    text-align: center;
}


.stage-indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 5px #59b894;
    transition: all 0.5s ease;
}
</style>
