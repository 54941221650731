<template>
    <div class="options">
        <h3>{{ heading }}</h3>

        <ul class="options-list">
            <li class="option"
             @click="select(option.id)"
             :id="option.id"
             v-for="option in options"
             :key="option.id">
                <div class="top-info">
                    <img :src="option.icon"
                     class="icon"
                     alt="icon">
                    <div class="text-group">
                        <h3>{{ option.name }}</h3>
                        <p>{{ option.shortText }}</p>
                    </div>
                </div>

                <ul class="additional"
                 v-if="selected == option.id && mode == 'multiple'">
                    <li>{{ option.price }}</li>
                    <li>{{ $t('dashboard.pages.booking.modal.lasts') }} {{ option.time }}</li>
                    <li v-for="value in option.other"
                     :key="value.text">{{ value.text }}</li>
                </ul>
            </li>
        </ul>

    </div>
</template>
<script>


export default {
    name: "OptionsSelect",
    components: {

    },
    data() {
        return {
            selected: null,
        }
    },
    props: {
        heading: String,
        options: Array,
        mode: {
            type: String,
            default: 'multiple'
        }
    },
    methods: {
        select(id) {
            const previoslySelected = document.querySelector(".selected");
            if (previoslySelected) {
                previoslySelected.classList.remove("selected");
            }
            const active = document.querySelector('#' + id);
            active.classList.toggle('selected');
            this.selected = id;
            this.$emit('selected', id);
        }
    }

}
</script>
<style scoped>
.additional {
    margin-top: 20px;
    font-size: 12px;
    color: var(--text-gray);
    list-style: disc;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.icon {
    max-width: 15px;
    width: 100%;
}

.top-info {
    display: flex;
    align-items: center;
    gap: 20px;
}

.selected {
    border: 2px solid var(--dark-blue) !important;
}

.options {
    width: 100%;
}

.options-list {
    margin: 10px 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
}

.option {
    width: 100%;
    padding: 20px;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-radius: var(--br);
    background-color: var(--gray);

    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    align-items: flex-start;
    transition: all 0.2s ease-in-out;
}

.text-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.option p {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-gray);
}

.option:hover {
    background-color: var(--dark-gray);
}
</style>
