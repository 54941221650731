<template>
    <footer>
        <div class="footer">
            <div class="list">
                <h3>{{ $t('footer.legal') }}</h3>
                <ul>
                    <li>
                        <a href="https://app.35fit.com/resources/ClubRules.pdf">{{ $t('footer.rules') }}</a>
                    </li>
                    <li>
                        <a href="/docs/GDPR.pdf">{{ $t('footer.privacy') }}</a>
                    </li>
                    <li>
                        <a :href="contractLink()">{{ $t('footer.contract') }}</a>
                    </li>
                    <li>
                        <a :href="priceListLink()">{{ $t('footer.pricelist') }}</a>
                    </li>
                </ul>
            </div>

            <div class="list">
                <h3>{{ $t('footer.help') }}</h3>
                <ul>
                    <li>
                        <RouterLink to="/faq">{{ $t('footer.faq') }}</RouterLink>
                    </li>
                    <li>
                        <a href="/faq#about">{{ $t('footer.about') }}</a>
                    </li>
                    <li>
                        <a href="https://app.stebby.eu/pos/35fit.com">Stebby</a>
                    </li>
                </ul>
            </div>

            <div class="list">
                <h3>{{ $t('footer.contacts') }}</h3>
                <ul>
                    <li>
                        +372 5843 5415
                    </li>
                    <li>
                        info@35fit.com
                    </li>
                    <li>
                        Gonsiori 33, Tallinn
                    </li>
                </ul>
            </div>

            <div class="list">
                <h3>{{ $t('footer.social') }}</h3>
                <ul class="social-list">
                    <li>
                        <img :src="instagram"
                         alt="Instagram icon">
                        <a href="https://www.instagram.com/35fit_club/">Instagram</a>
                    </li>
                    <li>
                        <img :src="tiktok"
                         alt="Tik Tok icon">
                        <a href="https://www.tiktok.com/@35fit">Tik Tok</a>
                    </li>
                    <li>
                        <img :src="facebook"
                         alt="Facebook icon">
                        <a href="https://www.facebook.com/35fitclub/">Facebook</a>
                    </li>
                </ul>
            </div>

        </div>

        <div v-if="false"
         class="footer-bottom">
            <div class="footer-bottom-left">
            </div>
            <div class="footer-bottom-right">
                <p>35FIT OÜ © 2022</p>
            </div>
        </div>
    </footer>
</template>

<script>
import facebook from '@/assets/svg/facebook.svg'
import instagram from '@/assets/svg/instagram.svg'
import tiktok from '@/assets/svg/tiktok.svg'

export default {
    name: "FooterComp",
    data() {
        return {
            facebook,
            instagram,
            tiktok,
        }
    },
    methods: {
        contractLink() {
            return 'https://app.35fit.com/resources/client_contract_ET.pdf'
        },
        priceListLink() {
            return 'https://app.35fit.com/resources/Hinnakiri.pdf'
        }
    },
}

</script>

<style>
.footer-bottom {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--dark-gray);
}

.footer-bottom-right p,
.footer-bottom-left p {
    font-size: 13px;
    font-weight: 500;
}

footer {
    max-width: calc(960px + 1rem);
    margin: 0 auto;
    margin-bottom: 80px;
}

.footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    margin-bottom: 10px;
}

.list {
    width: 100%;
}

.list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.social-list li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.social-list li img {
    width: 18px;
    height: 18px;
}

.list li {
    margin: 15px 0;
    font-size: 14px;
}

@media (max-width: 980px) {
    footer {
        padding: 0 1rem;
    }
}

@media (max-width: 820px) {
    .footer {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 680px) {
    .footer {
        grid-template-columns: 1fr 1fr;
    }
}
</style>
