<template>
    <div v-if="(typeof this.ticket.next_payment_price ==
        'string')"
     class="payment-widget">
        <div class="headings">
            <h5>{{ $t('dashboard.pages.contract.paymentWidget.name') }}</h5>
            <h2 v-if="this.ticket.debt > 0"
             class="red">{{ $t('dashboard.pages.contract.paymentWidget.inDebt') }}</h2>
            <h2 class="text-warning"
             v-else-if="newInvoice(currentDate, this.ticket.next_pay, this.ticket.next_payment_price)">
                {{ $t('newInv') }}
            </h2>
            <h2 v-else>{{ $t('dashboard.pages.contract.paymentWidget.allOk') }}</h2>
        </div>
        <div class="current-contract-info">
            <div class="contract-info-row">
                <p>{{ $t('dashboard.pages.contract.paymentWidget.due') }}</p>
                <p>{{ this.ticket.next_pay }}</p>
            </div>
            <div v-if="this.ticket.debt > 0"
             class="contract-info-row">
                <p>{{ $t('dashboard.pages.contract.paymentWidget.toPay') }}</p>
                <p>€{{ this.ticket.debt }}</p>
            </div>
            <div v-else
             class="contract-info-row">
                <p>{{ $t('dashboard.pages.contract.paymentWidget.next') }}</p>
                <p>€{{ this.ticket.next_payment_price }}</p>
            </div>
            <div class="contract-buttons-group">
                <MainButton v-if="this.ticket.debt > 0"
                 class="top-marg red-btn"
                 :text="$t('dashboard.pages.contract.paymentWidget.pay')"
                 @click="$emit('pay')" />
                <div v-else
                 class="top-marg contract-buttons-group">
                    <MainButton v-if="this.ticket.next_payment_price != 0"
                     :text="$t('dashboard.pages.contract.paymentWidget.prepay')"
                     @click="$emit('pay')" />
                    <button v-if="this.ticket.debt == 0"
                     class="cancel-subscription "
                     @click="openModal">{{ $t('dashboard.pages.contract.paymentWidget.vacation') }}</button>
                </div>

            </div>
        </div>

        <Transition name="bounce">
            <ModalWindow v-if="showModal"
             :title="$t('dashboard.pages.contract.paymentWidget.modal.title')"
             :subtitle="$t('dashboard.pages.contract.paymentWidget.modal.subtitle')"
             @closeModal="this.showModal = !this.showModal;">
                <Transition name="bounce"
                 mode="out-in">
                    <LoaderView v-if="loader" />
                    <div v-else
                     class="modal-confirm-plan">
                        <img class="new-contract-img"
                         :src="vacation"
                         alt="contract">
                        <transition name="bounce">
                            <InputNotify v-if="warning.show"
                             :icon="warning.icon"
                             :text="warning.text"
                             class="full-width" />
                        </transition>
                        <p class="text-explain">
                            {{ $t('dashboard.pages.contract.paymentWidget.modal.text') }}
                        </p>

                        <p v-html="$t('dashboard.pages.contract.paymentWidget.modal.text2')"
                         class="text-explain">
                        </p>
                        <InputNotify :icon="info"
                         :text="$t('dashboard.pages.contract.paymentWidget.modal.period') + processVacationDate()"
                         class="full-width" />


                        <label class="checkbox-container">
                            <input type="checkbox"
                             v-model="confirmedVacation">
                            <span class="checkmark"></span>
                            <p>
                                {{ $t('dashboard.pages.contract.paymentWidget.modal.agreement') }}
                            </p>
                        </label>
                        <OptionsSelect class="full-width"
                         :heading="$t('dashboard.pages.contract.paymentWidget.modal.selectPeriod')"
                         :options="vacationOptions"
                         mode="single"
                         @selected="(id) => this.period =
                             vacationOptions.find(option => option.id === id).duration" />

                        <MainButton v-if="period != null && confirmedVacation"
                         :widthRestrict="true"
                         :text="$t('dashboard.pages.contract.paymentWidget.modal.request')"
                         @click="requestVacation" />
                    </div>
                </Transition>
            </ModalWindow>
        </Transition>

        <NotifyWarning v-if="success.show"
         title="Success"
         :icon="success.icon"
         :text="success.text"
         color="green"
         @close="success.show = false" />

    </div>
    <div v-else
     class="payment-widget">
        <div class="headings">
            <h5>{{ $t('dashboard.pages.contract.paymentWidget.name') }}</h5>
            <h2>{{ $t('dashboard.pages.contract.paymentWidget.noPayment') }}</h2>
        </div>
    </div>
</template>
<script>
import MainButton from '../buttons/MainButton.vue';
import ModalWindow from '../ModalWindow.vue';
import vacation from '@/assets/svg/vacation.svg';
import OptionsSelect from '../selectors/OptionsSelect.vue';
import InputNotify from '../InputNotify.vue';
import calendar from '@/assets/svg/calendar-dash.svg';
import xmark from '@/assets/svg/xmark.svg';
import info from '@/assets/svg/info.svg';
import NotifyWarning from '../warnings/NotifyWarning.vue';
import checkmark from '@/assets/svg/check.svg';
import LoaderView from '../buttons/LoaderView.vue';

export default {
    name: 'PaymentWidget',
    props: {
        ticket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            vacation,
            loader: false,
            checkmark,
            confirmedVacation: false,
            info,
            xmark,
            currentDate: this.currentDateString(),
            vacationOptions: [
                {
                    id: "one",
                    duration: 1,
                    name: '1' + ' ' + this.$t('dashboard.pages.contract.paymentWidget.modal.month'),
                    icon: calendar,
                    shortText: this.$t('dashboard.pages.contract.paymentWidget.modal.short1')
                },
                {
                    id: "two",
                    duration: 2,
                    name: '2 ' + this.$t('dashboard.pages.contract.paymentWidget.modal.months'),
                    icon: calendar,
                    shortText: this.$t('dashboard.pages.contract.paymentWidget.modal.short2')
                }
            ],
            showModal: false,
            period: null,
            warning: {
                icon: null,
                text: null,
                show: false
            },
            success: {
                icon: null,
                text: null,
                show: false
            }
        }
    },
    components: {
        MainButton,
        ModalWindow,
        InputNotify,
        OptionsSelect,
        NotifyWarning,
        LoaderView
    },
    methods: {
        openModal() {
            this.reason = null;
            const body = document.querySelector('body');
            this.showModal = !this.showModal;
            body.classList.add('menu-open');
        },
        newInvoice(current, next, next_payment_price) {
            // transform next fromat of dd.mm.yyyy to yyyy-mm-dd
            const date = next.split('.');
            const day = date[0];
            const month = date[1];
            const year = date[2];
            const newDate = year + '-' + month + '-' + day;

            if (next_payment_price == 0) {
                return false
            }

            if (new Date(current).getTime() >= new Date(newDate).getTime()) {
                return true
            }
            return false
        },
        currentDateString() {
            const date = new Date();
            // add 10 days to the current date
            date.setDate(date.getDate() + 10);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;

            return year + '-' + monthString + '-' + dayString;
        },
        processVacationDate() {
            const date = new Date();
            // add 10 days to the current date
            date.setDate(date.getDate() + 10);
            let rawNextPay = this.ticket.next_pay.split('.').reverse().join('-');
            const next_pay = Date.parse(rawNextPay);
            let vacationDate = new Date(next_pay);
            if (date >= next_pay) {
                // add 1 month to the current date
                vacationDate.setMonth(vacationDate.getMonth() + 1);
            }


            const day = vacationDate.getDate();
            const month = vacationDate.getMonth() + 1;
            const year = vacationDate.getFullYear();
            const dayString = day < 10 ? '0' + day : day;
            const monthString = month < 10 ? '0' + month : month;

            return dayString + '.' + monthString + '.' + year;
        },
        requestVacation() {
            this.loader = true;
            fetch('https://app.35fit.com/api/users/onhold', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' +
                        localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    duration: this.period
                })
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.success) {
                        this.openModal();
                        this.loader = false;
                        this.$emit('vacationConfirmed');
                        this.success = {
                            icon: this.checkmark,
                            text: data.message,
                            show: true
                        }


                        return
                    }
                    this.loader = false;
                    this.warning = {
                        icon: this.xmark,
                        text: data.error,
                        show: true
                    }

                })
                .catch(err => {
                    console.log(err)
                    if (err.message == 401) {
                        this.openModal();
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.loader = false;
                    this.warning = {
                        icon: this.xmark,
                        text: err.message,
                        show: true
                    }
                    // this.loader = false;

                })
        }
    },
    mounted() {
        if (this.ticket.debt > 0) {
            let paymentWidget = document.querySelector('.payment-widget');
            paymentWidget.classList.add('red-border');
        }
    }
}
</script>
<style scoped>
.text-explain {
    text-align: left;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
}


.payment-widget {
    padding: 20px;
    width: 100%;
    border-radius: var(--card-br);
    background-color: var(--gray);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.red {
    color: var(--red) !important;
}

.headings {
    width: 100%;
}

.headings h2 {
    text-transform: uppercase;
    color: var(--light-green);
}

.headings h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--text-gray);
}
</style>
