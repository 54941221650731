<template>
  <main class="home">
    <AdvertSlider v-once />

    <section ref="sceneOne"
     class="home-features">
      <h2 ref="sceneOneTitle">{{ $t('home.firstSection.h1') }}</h2>
      <h2 ref="sceneOneTitle2"
       class="gray">{{ $t('home.firstSection.h2') }}</h2>
      <CardsGrid v-once
       :cardsContent="cardsContent" />
    </section>

    <section ref="sceneTwo"
     class="regimes">
      <h2 ref="sceneTwoTitle">{{ $t('home.secondSection.h1') }}</h2>
      <h2 ref="sceneTwoTitle2"
       class="gray">{{ $t('home.secondSection.h2') }}</h2>
      <CardsGrid v-once
       :cardsContent="cardsContent2" />
    </section>

    <section ref="sceneThree"
     class="benefits">
      <h2 ref="sceneThreeTitle">{{ $t('home.thirdSection.h1') }}</h2>
      <h2 ref="sceneThreeTitle2"
       class="gray">{{ $t('home.thirdSection.h2') }}</h2>
      <GrayCardUnited v-once
       :content="features" />
    </section>

    <section class="media-content">
      <h2 ref="sceneFourTitle">{{ this.$t('home.mediaContent.h1') }}</h2>
      <h2 ref="sceneFourTitle2"
       class="gray">{{ this.$t('home.mediaContent.h2') }}</h2>
      <MediaContent v-once
       :imageMode="powerPlateMedia.mode"
       :heading="powerPlateMedia.heading"
       :image="powerPlateMedia.image"
       :text="powerPlateMedia.text"
       :modalTitle="powerPlateMedia.modalTitle"
       :subheading="powerPlateMedia.subheading"
       :modalSubtitle="powerPlateMedia.modalSubtitle">

        <MediaContentInner v-once
         :image="ppmedia1"
         :title="$t('home.mediaContent.powerPlate.mediaInner.title')"
         :text="$t('home.mediaContent.powerPlate.mediaInner.text')" />

        <MediaContentInner v-once
         :image="ppmedia2"
         :title="$t('home.mediaContent.powerPlate.mediaInner2.title')"
         :text="$t('home.mediaContent.powerPlate.mediaInner2.text')" />

        <LearnMoreButton @click="toRegister"
         :text="$t('home.mediaContent.powerPlate.register')"
         v-if="loggedOut" />

      </MediaContent>

      <MediaContent v-once
       :imageMode="truggeMedia.mode"
       :heading="truggeMedia.heading"
       :image="truggeMedia.image"
       :subheading="truggeMedia.subheading"
       :text="truggeMedia.text"
       :modalTitle="truggeMedia.modalTitle"
       :modalSubtitle="truggeMedia.modalSubtitle">
        <MediaContentInner v-once
         :image="trugge"
         :title="$t('home.mediaContent.trugge.mediaInner.title')"
         :text="$t('home.mediaContent.trugge.mediaInner.text')" />
        <MediaContentInner v-once
         :image="trugge2"
         :title="$t('home.mediaContent.trugge.mediaInner2.title')"
         :text="$t('home.mediaContent.trugge.mediaInner2.text')" />

        <LearnMoreButton @click="toRegister"
         :text="$t('home.mediaContent.powerPlate.register')"
         v-if="loggedOut" />

      </MediaContent>

      <MediaContent v-once
       :imageMode="testsMedia.mode"
       :heading="testsMedia.heading"
       :image="testsMedia.image"
       :subheading="testsMedia.subheading"
       :text="testsMedia.text"
       :modalTitle="testsMedia.modalTitle"
       :modalSubtitle="testsMedia.modalSubtitle">

        <MediaContentInner v-once
         :image="tests1"
         :title="$t('home.mediaContent.tests.mediaInner.title')"
         :text="$t('home.mediaContent.tests.mediaInner.text')" />
        <MediaContentInner v-once
         :image="tests2"
         :title="$t('home.mediaContent.tests.mediaInner2.title')"
         :text="$t('home.mediaContent.tests.mediaInner2.text')" />

        <MediaContentInner v-once
         :image="tests3"
         :title="$t('home.mediaContent.tests.mediaInner3.title')"
         :text="$t('home.mediaContent.tests.mediaInner3.text')" />


        <LearnMoreButton @click="toRegister"
         :text="$t('home.mediaContent.powerPlate.register')"
         v-if="loggedOut" />

      </MediaContent>

    </section>

    <PhotoGallery />

    <section v-if="false"
     class="faq">
      <h2 style="margin-bottom: 20px;">Got some questions?</h2>
      <!-- <FaqMain /> -->
    </section>

  </main>
</template>

<script>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);
// @ is an alias to /src
import CardsGrid from '@/components/CardsGrid.vue'
// import IntroHome from '@/components/IntroHome.vue'
import GrayCardUnited from '@/components/GrayCardUnited.vue'
import MediaContent from '@/components/MediaContent.vue'
import MediaContentInner from '@/components/MediaContentInner.vue'
// import FaqMain from '@/components/layout/FaqMain.vue'
import cycling from '@/assets/svg/cycling.svg'
import clockXmark from '@/assets/svg/clock-xmark.svg'
import repeat from '@/assets/svg/repeat.svg'
import card1 from '@/assets/img/home/card1.webp'
import card2 from '@/assets/img/home/equipment.webp'
import card3 from '@/assets/img/home/card3.webp'
import card4 from '@/assets/img/home/card4.webp'
import card5 from '@/assets/img/home/card5.webp'
import card6 from '@/assets/img/home/card6.webp'
import card7 from '@/assets/img/home/card7.webp'
import powerPlateImg from '@/assets/img/home/powerplate.webp'
import truggeTitle from '@/assets/img/home/trugge-title.webp'
import trugge from '@/assets/img/home/trugge.webp'
import trugge2 from '@/assets/img/home/trugge2.webp'
import LearnMoreButton from '@/components/buttons/LearnMoreButton.vue'
import ppmedia1 from '@/assets/img/home/ppmedia1.webp'
import ppmedia2 from '@/assets/img/home/ppmedia2.webp'
import tests1 from '@/assets/img/home/tests1.webp'
import tests2 from '@/assets/img/home/tests2.webp'
import tests3 from '@/assets/img/home/tests3.webp'
import testsTitle from '@/assets/img/home/tests-title.webp'
import { useMeta } from 'vue-meta'
import AdvertSlider from '@/components/intros/AdvertSlider.vue';
import PhotoGallery from '@/components/PhotoGallery.vue';
//import SummerPlan from '@/components/intros/SummerPlan.vue';

export default {
  name: 'HomeView',
  components: {
    CardsGrid,
    GrayCardUnited,
    MediaContent,
    //FaqMain,
    MediaContentInner,
    LearnMoreButton,
    AdvertSlider,
    PhotoGallery
  },
  beforeMount() {
    useMeta({
      title: this.$t('pagenames.home') + " | AI gym",
      link: [
        { rel: 'canonical', href: 'https://35fit.com/' },
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { property: "og:title", content: "35FIT | " + this.$t('pagenames.home') + " | AI gym" },
        { property: "og:description", content: this.$t('meta.home') },
        { property: "og:url", content: "https://35fit.com" },
      ],
      description: this.$t('meta.home'),
    })
  },
  data() {
    return {
      powerPlateImg,
      loggedOut: true,
      tests1,
      tests2,
      tests3,
      ppmedia1,
      ppmedia2,
      trugge2,
      trugge,
      powerPlateMedia: {
        mode: 'small',
        heading: this.$t('home.mediaContent.powerPlate.heading'),
        image: powerPlateImg,
        subheading: this.$t('home.mediaContent.powerPlate.subheading'),
        text: this.$t('home.mediaContent.powerPlate.text'),
        modalTitle: this.$t('home.mediaContent.powerPlate.modalTitle'),
        modalSubtitle: this.$t('home.mediaContent.powerPlate.modalSubtitle')
      },
      truggeMedia: {
        mode: 'small-reverse',
        heading: this.$t('home.mediaContent.trugge.heading'),
        image: truggeTitle,
        subheading: this.$t('home.mediaContent.trugge.subheading'),
        text: this.$t('home.mediaContent.trugge.text'),
        modalTitle: this.$t('home.mediaContent.trugge.modalTitle'),
        modalSubtitle: this.$t('home.mediaContent.trugge.modalSubtitle')
      },
      testsMedia: {
        mode: 'small',
        heading: this.$t('home.mediaContent.tests.heading'),
        image: testsTitle,
        subheading: this.$t('home.mediaContent.tests.subheading'),
        text: this.$t('home.mediaContent.tests.text'),
        modalTitle: this.$t('home.mediaContent.tests.modalTitle'),
        modalSubtitle: this.$t('home.mediaContent.tests.modalSubtitle')
      },
      cardsContent: [{
        id: 1,
        title: this.$t('home.firstSection.card1.title'),
        text: this.$t('home.firstSection.card1.text'),
        image: card1
      },
      {
        id: 2,
        title: this.$t('home.firstSection.card2.title'),
        text: this.$t('home.firstSection.card2.text'),
        image: card2
      },
      {
        id: 3,
        title: this.$t('home.firstSection.card3.title'),
        text: this.$t('home.firstSection.card3.text'),
        image: card3
      },
      ],
      cardsContent2: [{
        id: 1,
        title: this.$t('home.secondSection.card1.title'),
        text: this.$t('home.secondSection.card1.text'),
        image: card4
      },
      {
        id: 2,
        title: this.$t('home.secondSection.card2.title'),
        text: this.$t('home.secondSection.card2.text'),
        image: card7
      },
      {
        id: 3,
        title: this.$t('home.secondSection.card3.title'),
        text: this.$t('home.secondSection.card3.text'),
        image: card6
      },
      {
        id: 4,
        title: this.$t('home.secondSection.card4.title'),
        text: this.$t('home.secondSection.card4.text'),
        image: card5
      },
      ],
      features: [
        {
          id: 1,
          icon: cycling,
          heading: this.$t('home.thirdSection.card1.title'),
          text: this.$t('home.thirdSection.card1.text'),
        },
        {
          id: 2,
          icon: clockXmark,
          heading: this.$t('home.thirdSection.card2.title'),
          text: this.$t('home.thirdSection.card2.text'),
        },
        {
          id: 3,
          icon: repeat,
          heading: this.$t('home.thirdSection.card3.title'),
          text: this.$t('home.thirdSection.card3.text'),
        }
      ],
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.loggedOut = false;
    } else {
      this.loggedOut = true;
    }
    this.animateScene();
  },

  methods: {
    toRegister() {
      const body = document.querySelector('body');
      body.classList.remove('menu-open');
      this.$router.push('/register');
    },
    headingsScene(start, objects) {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: start,
          start: 'top 70%',
          end: 'bottom 70%',
          markers: false,
          toggleActions: 'play none none reverse',
        },
        defaults: {
          ease: 'power2.inOut'
        },

      });

      tl.fromTo(objects, {
        y: -15,
        opacity: 0
      }, {
        y: 0,
        opacity: 1,
        duration: 0.3,
        stagger: 0.2,
      });

    },
    animateScene() {
      this.headingsScene(this.$refs.sceneOne,
        [this.$refs.sceneOneTitle, this.$refs.sceneOneTitle2]);
      this.headingsScene(this.$refs.sceneTwo,
        [this.$refs.sceneTwoTitle, this.$refs.sceneTwoTitle2]);
      this.headingsScene(this.$refs.sceneThree,
        [this.$refs.sceneThreeTitle, this.$refs.sceneThreeTitle2]);
      this.headingsScene(this.$refs.sceneFourTitle,
        [this.$refs.sceneFourTitle, this.$refs.sceneFourTitle2]);

    }
  },


}
</script>

<style>
.section,
.training-feature,
.home-features,
.regimes,
.benefits,
.media-content,
.faq {
  display: block;
  margin: 80px auto;
  max-width: calc(960px + 1rem);
}

.page-start {
  padding: var(--padding);
  display: block;
  margin: 164px auto 0px auto;
}

.gray {
  color: var(--text-gray);
}

@media screen and (max-width: 980px) {

  .page-start,
  .section,
  .home-features,
  .regimes,
  .benefits,
  .media-content,
  .faq {
    padding: 0 20px;
  }
}

@media screen and (max-width: 660px) {

  .page-start,
  .section,
  .home-features,
  .regimes,
  .benefits,
  .media-content,
  .faq {
    padding: var(--padding);
  }
}
</style>
