<template>
    <div @click="select(plan)"
     role="button"
     class="pricing-grid-card"
     :class="(plan.id === 3049855 || plan.id === 3064045) ? 'red-border' : ''">
        <div class="pricing-grid-card-top">
            <div class="plan-name">
                <h5>{{ (plan.id === 2650579 || plan.id === 2650570) ? 'Promo' : plan.duration }}</h5>
                <h2>
                    {{ plan.name }}
                </h2>
            </div>

            <h2 v-if="plan.id === 3049855 || plan.id === 3064045"
             class="pricing-card-small pricing-discount">
                <span class="pricing-discount-price">
                    €{{ plan.id === 3064045 ? '79' : '414' }}
                </span>
                <span style="color: var(--red)">
                    <span v-if="(plan.monthly > 0)"
                     class="euro">€
                    </span> {{ plan.monthly }}
                </span>
            </h2>
            <h2 v-else
             class="pricing-card-small">
                <span v-if="(plan.monthly > 0)"
                 class="euro">€
                </span>
                {{ (plan.monthly > 0) ? plan.monthly : $t('pricing.other.free') }}
                <span v-if="(this.mode == 'plans' && plan.id != 2650579 || plan.id === 2650570)">
                    {{ plan.id === 3049855 ? "" : '/' + $t('pricing.other.month') }}
                </span>
            </h2>
        </div>
        <transition name="bounce"
         mode="out-in">
            <ul :class="{ 'bottom-margin': plan.included.length > 1 }"
             v-if="!active">
                <li>
                    <div class="list-icon"></div>
                    {{ plan.included[0] }}
                </li>
            </ul>
            <ul v-else>
                <li v-for="included in plan.included"
                 :key="included">
                    <div class="list-icon"></div>
                    {{ included }}
                </li>
            </ul>
        </transition>

        <button v-if="plan.included.length > 1"
         class="show-more">
            <img :class="{ 'show-more-rotate': active }"
             src="/svg/chevron.down.svg"
             alt="down">
            {{ this.text }}
        </button>
    </div>
</template>
<script>
import check from '@/assets/svg/check.svg'

import tap from '@/assets/svg/tap.svg'


export default {
    name: "PricingItem",

    props: {
        plan: Object,
        mode: {
            type: String,
            default: "plans"
        },
        opened: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            check,
            tap,
            text: this.$t('pricing.other.more'),
            active: false,
        }
    },
    methods: {
        select(plan) {
            this.showMore();
            if (plan.id != 2650579 && plan.id != 2650570) {
                this.$emit('select', this.plan)
            }
        },
        showMore() {
            this.text = this.active ? this.$t('pricing.other.more') : this.$t('pricing.other.less')
            this.active = !this.active
        },

    },
    mounted() {
        if (this.opened) {
            this.active = true
            this.text = "Less"
        }
    },



}
</script>
<style>
.show-more {
    color: lightgray;
    font: var(--font);
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    right: 20px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    transition: all 0.3s ease-in-out;
}


.show-more img {
    height: 5px;
    transition: all 0.3s ease-in-out;
}

.show-more-rotate {
    transform: rotate(180deg);
}

.selected-plan {
    border: 2px solid var(--dark-blue) !important;
}

.pricing-card-tapper {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 20px;
}

.pricing-discount-price {
    text-decoration: line-through;
    text-decoration-color: var(--red);
    color: gray;
}

.pricing-discount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.tapper-click {
    animation: tap 2.5s ease-in-out;

}

.euro {
    font-size: 14px;
}

@keyframes tap {
    0% {
        opacity: 0;
    }

    28% {
        opacity: 1;
    }

    30% {
        transform: translateX(-340%);
        scale: 1;
    }

    70% {
        transform: translateX(-370%);
        scale: 0.7;
    }


}
</style>
